import React from "react";
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import loadable from "@loadable/component";
import LayoutTwo from "../components/layoutTwo";

import NewsDetailImg from "../images/news_detail_img.png";
import NewsImg1 from "../images/news_img_1.png";
import NewsImg2 from "../images/news_img_2.png";
import NewsImg3 from "../images/news_img_3.png";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const NewsDetailDesc = loadable(() => import("../components/NewsDetailDesc/NewsDetailDesc"));
const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModule"));

const StaticTemplate = ({ data }, props) => {
    const PageData = data?.strapiPage
    return (
        <LayoutTwo popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title} subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug} parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title} parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug} title={PageData.title}
            />
            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.plain-content" && <StaticIntro {...module} pagename={PageData.title} />}
                        {module.strapi_component === "page-modules.image-and-content" && module.layout === "fullwidth" && <ValuationModule {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} />}

                    </>
                )
            })}
        </LayoutTwo>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default StaticTemplate

export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
              ...ImageAndContentFragment
            }
        }
    }
  }
`